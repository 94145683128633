import React, { useState } from "react"
import Roll from "react-reveal/Roll"
import LocationOnIcon from "@material-ui/icons/LocationOn"
import PhoneIcon from "@material-ui/icons/Phone"
import EmailIcon from "@material-ui/icons/Email"
import ContactModal from "../modals/contactModal"

const Contact = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false)
  return (
    <section className="bg-bgColor">
      <div className="text-xl md:text-2xl lg:text-3xl text-mainColor p-1 my-4 text-center font-bold">
        Contact Us
      </div>
      <hr className="horizontalLine" />
      <div className="grid grid-cols-1 md:grid-cols-3 max-w-screen-xl w-4/5 md:w-full m-auto">
        <Roll cascade>
          <div className="flex justify-center border-r text-center">
            <div className="">
              <LocationOnIcon className="icons" />
              <p className="text-mainColor text-xl">Address</p>
              <address className="text-mainColor">Bucharest, Romania</address>
            </div>
          </div>
        </Roll>
        <Roll cascade>
          <div className="flex justify-center border-r text-center">
            <div className="">
              <PhoneIcon className="icons" />
              <p className="text-mainColor text-xl">Phone Number</p>
              <p className="text-mainColor">
                <a className="text-mainColor" href="tel:+40 312295114">
                  +40 312295114
                </a>
              </p>
            </div>
          </div>
        </Roll>
        <Roll cascade>
          <div className="flex justify-center border-r text-center">
            <div className="">
              <EmailIcon className="icons" />
              <p className="text-mainColor text-xl">Email</p>
              <button
                onClick={() => setModalIsOpen(true)}
                className="bg-mainColor text-white p-2 rounded-md"
              >
                {" "}
                Contact Us
              </button>
            </div>
          </div>
        </Roll>
      </div>
      {modalIsOpen && (
        <ContactModal
          modalIsOpen={modalIsOpen}
          setModalIsOpen={setModalIsOpen}
        />
      )}
    </section>
  )
}
export default Contact
