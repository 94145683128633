import React from "react"
//import RictaLogo from '../../images/rictaLogo.svg'
import Fade from "react-reveal/Fade"
import ProductsImage from "../../images/products.svg"

const Introduction = () => {
  return (
    <header className="hero bg-mainColor bg-no-repeat bg-center bg-cover resize">
      <svg viewBox="0 0 100 100" preserveAspectRatio="none">
        <polygon points="0,100 100,0 100,100" />
      </svg>
      <div className="flex flex-col md:flex-row items-center justify-center h-100 max-w-screen-xl w-4/5 md:w-full m-auto">
        <div className="flex-1">
          <Fade left>
            <div className="text-white">
              <p className="text-gray-200 p-0 m-0">A Software Boutique</p>
              <p className="text-white text-2xl font-bold">
                Focused on developing commercial and tailored cutting-edge web
                browser solutions for Law Enforcement, Osint Analyst, Techops
                engineers, MSSP and Enterprises.
              </p>
              <a
                href="#products"
                className="text-white py-2 px-7 shadow rounded font-bold text-xl no-underline inline-block mt-3 bg-ricta-secondary hover:opacity-90 w-full text-center md:w-auto"
              >
                Try our products
              </a>
            </div>
          </Fade>
        </div>

        <div className="flex-1 products-img-container mt-4 md:mt-0">
          <Fade right>
            <img
              className="products-img"
              alt="Products Images"
              src={ProductsImage}
            />
          </Fade>
        </div>
      </div>
      {/* <div
        className=" py-12 bg-opacity-50 bg-white flex items-center justify-center max-w-screen-xl w-4/5 md:w-full m-auto"
        style={{ background: "rgba(0,0,0,0.0)" }}
      >
        <div className="mx-2 text-center">
          {/* <Fade left>
                        <img src={RictaLogo} className="rictaLogo"/>
                    </Fade> */}
      {/*} <Fade left>
            <h1 className="text-mainColor whitespace-normal break-words font-bold mt-10 text-xl md:text-3xl lg:text-4xl">
              A Software Boutique company with focus on developing new browsing
              solutions for <br /> Forensic specialists and OSINT analysts.
            </h1>
          </Fade>
        </div>
      </div> */}
    </header>
  )
}
export default Introduction
